<template>
  <div>
    <HoangTest/>
  </div>
</template>

<script>
import baseUrl from "@/base-url.js";
import HoangTest from "@/views/test/HoangTest.vue";
export default {
  components: {HoangTest},
  data(){
    return{
      // test:`asdas`+'\n'+ `adssagefsatger`
      test:false
    }
  },
  created() {
    const date=this.$funcCommon.formatMY(2023,8)
    console.log(date)
  },
  methods: {
    handleCloseTemplateDeleteDialog(val){
      this.test= val
    },
    abc() {
      this.test=!this.test
      // this.$store.commit('finance2Module/get_status_excel')
      // alert("test: " + baseUrl.mytest);
    },
    api() {
      alert("api: " + baseUrl.myApi);
    },
  },
};
</script>

<style lang="scss" scoped></style>
